import { Box } from '@chakra-ui/react'
import React from 'react'

type Props = {
  status: string | null
}

const AuthSessionStatus = ({ status, ...props }: Props) => (
  <>
    {status && (
      <Box textAlign='center' {...props}>
        {status}
      </Box>
    )}
  </>
)

export default AuthSessionStatus
