import AuthSessionStatus from '@/components/AuthSessionStatus'
import AuthValidationErrors from '@/components/AuthValidationErrors'
import { SocialLoginButtons } from '@/components/SocialLoginButtons'
import { useAuth } from '@/hooks/auth'
import {
  Box,
  Button,
  Container,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { captureEvent } from '@sentry/nextjs'
import { NextSeo } from 'next-seo'
import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import { useRouter } from 'next/router'
import { FormEvent, useEffect, useState } from 'react'

const getRedirectTo = (params: { query: NextParsedUrlQuery }): string => {
  if (typeof params.query.redirectTo === 'string') {
    return params.query.redirectTo
  }

  return '/dashboard'
}

const Login = () => {
  const router = useRouter()
  const redirectTo = getRedirectTo({ query: router.query })

  const { login } = useAuth({
    middleware: 'guest',
    redirectIfAuthenticated: redirectTo,
  })

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState<string[]>([])
  const [status, setStatus] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const toast = useToast()

  useEffect(() => {
    if (
      router.query.reset &&
      router.query.reset.length > 0 &&
      errors?.length == 0
    ) {
      toast({
        title: atob(router.query.reset as string),
        isClosable: true,
      })
    }
  }, [router.query.reset, errors?.length, toast])

  useEffect(() => {
    if (router.query.provider) {
      setErrors([
        `You have logged in before with your ${router.query.provider} account, try that account instead.`,
      ])
    }
  }, [router.query.provider])

  const submitForm = async (event: FormEvent) => {
    event.preventDefault()
    setSubmitting(true)
    await login({ email, password, setErrors, setStatus, setSubmitting })
      .then(() => {
        toast({
          title: 'Sending login request',
          description: 'Just a moment…',
          duration: 5000,
          status: 'success',
          isClosable: true,
        })
      })
      .catch(error => {
        setSubmitting(false)
        setErrors(error.errors)
        toast({
          title: 'Error',
          description: error.message,
          duration: 5000,
          status: 'error',
          isClosable: true,
        })
        captureEvent(error, { data: 'Error logging in' })
      })
  }

  return (
    <>
      <NextSeo title='Login to Deboss Nation' />
      <Container maxW='container.md'>
        <Stack spacing={20} py={24}>
          <SocialLoginButtons />
          <form onSubmit={submitForm}>
            {/* Session Status */}
            {status && <AuthSessionStatus status={status} />}
            {/* Validation Errors */}
            {errors && <AuthValidationErrors errors={errors} />}
            <Stack spacing={8}>
              <Heading textAlign='center'>
                Or, login with your email address
              </Heading>
              <Stack spacing={6} px={{ lg: 32 }}>
                <Box>
                  <FormLabel htmlFor='email'>Email</FormLabel>
                  <Input
                    id='email'
                    type='email'
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    required
                    autoFocus
                  />
                </Box>
                <Box>
                  <FormLabel htmlFor='password'>Password</FormLabel>
                  <Input
                    id='password'
                    type='password'
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                    required
                    autoComplete='current-password'
                  />
                </Box>
                <Button type='submit' isLoading={submitting} colorScheme='red'>
                  Login
                </Button>
                <Stack
                  spacing={4}
                  direction={{ base: 'column', sm: 'row' }}
                  justifyContent='center'>
                  <Link href='/forgot-password'>Forgot your password?</Link>
                  <Link
                    href={`/register${
                      router.query.redirectTo
                        ? `?redirectTo=${router.query.redirectTo}`
                        : ''
                    }`}>
                    Create an account
                  </Link>
                </Stack>
              </Stack>
            </Stack>
          </form>
          <Text size='sm' align='center'>
            Note: The Twitter login has been removed due to Twitter’s new
            restrictions. If you previously logged in with Twitter, you will{' '}
            <Link href='/contact'>
              <u>contact support</u>
            </Link>{' '}
            to recover your account.
          </Text>
        </Stack>
      </Container>
    </>
  )
}

export default Login
