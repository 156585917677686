import { Link, List, ListItem, Stack, Text } from '@chakra-ui/react'
import NextLink from 'next/link'
import React from 'react'

type Props = {
  errors: any[]
}

const AuthValidationErrors = ({ errors, ...props }: Props) => (
  <>
    {errors.length > 0 && (
      <Stack
        {...props}
        mb={12}
        spacing={3}
        textAlign='center'
        borderColor='red.400'
        borderWidth={2}
        p={8}>
        <Text fontSize='sm' fontWeight='bold' textTransform='uppercase'>
          Something went wrong.
        </Text>

        <List spacing={2}>
          {errors.map(error => (
            <ListItem key={error}>{error}</ListItem>
          ))}
          <ListItem>
            If you need help, please{' '}
            <NextLink href='/contact' passHref legacyBehavior>
              <Link textDecoration='underline'>contact support.</Link>
            </NextLink>
          </ListItem>
        </List>
      </Stack>
    )}
  </>
)

export default AuthValidationErrors
