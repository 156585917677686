import { Button, Heading, Icon, Stack } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { FaFacebook, FaGoogle } from 'react-icons/fa'

const socialButtons = [
  {
    icon: <Icon as={FaGoogle} title='' />,
    label: 'Google',
    color: 'google',
    slug: 'google',
  },
  {
    icon: <Icon as={FaFacebook} title='' />,
    label: 'Facebook',
    color: 'facebook',
    slug: 'facebook',
  },
]

export const SocialLoginButtons = () => {
  const { query } = useRouter()

  const redirectTo = query.redirectTo || '/dashboard'

  return (
    <Stack spacing={8}>
      <Heading textAlign='center'>Login with your social account</Heading>
      <Stack direction={{ base: 'column', md: 'row' }} justifyContent='center'>
        {socialButtons.map(({ icon, label, color, slug }) => (
          <Button
            as='a'
            size='lg'
            backgroundColor={color}
            color='white'
            leftIcon={icon}
            href={`${process.env.NEXT_PUBLIC_BACKEND_URL}/oauth/${slug}?redirectTo=${redirectTo}`}
            _hover={{ backgroundColor: `${color}` }}
            textTransform='none'
            key={slug}>
            {label}
          </Button>
        ))}
      </Stack>
    </Stack>
  )
}
